class MA11YC {
	static _apply(selector, attributesArray) {
		const elements = [...document.querySelectorAll(selector)];

		elements.forEach((element) => {
			element.setAttribute(...attributesArray);
		});
	}

	static enhance() {
		if (typeof arguments[0] === "object") {
			for (const args of Object.entries(arguments[0])) {
				MA11YC._apply(...args);
			}
		} else {
			MA11YC._apply(...arguments);
		}
	}

	static observe(element, changes) {
		const changesArray = Array.isArray(changes) ? changes : [changes];

		const callback = (mutationsList) => {
			for (const mutation of mutationsList) {
				const attr = mutation.attributeName.toLowerCase();

				changesArray.forEach((change) => {
					if (attr === change.attr) {
						const [attribute, trueVal, falseVal] = change.toggle;

						if (attr === "class") {
							element.setAttribute(
								attribute,
								attr === "class"
									? element.classList.contains(change.value)
										? trueVal
										: falseVal
									: element.hasAttribute(change.attr) &&
									  element.getAttribute(change.attr) === change.value
									? trueVal
									: falseVal
							);
						}
					}
				});
			}
		};

		const observer = new MutationObserver(callback);
		observer.observe(element, {
			attributes: true,
			childList: false,
			subtree: false,
		});
	}
}
